@import '../variables';

.results-page-container {
  min-width: 1300px;
}

.btn-refresh {
  width: 160px;
}

.btn-icon {
  font-size: 1.6rem;
  vertical-align: middle;
  padding-right: 5px;
  padding-bottom: 7px;
}

.sequence-view-label {
  font-size: 0.75rem;
}

.results-table {
  box-shadow: 2px 2px 3px 3px rgba($gray-900, 0.25);
  border: 1px solid $gray-400;

  thead,
  tbody {
    border: none;
    border-image-width: 0;
  }

  .results-table-row {
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;

    .results-table-header {
      text-align: center;
      border-top: 0;
    }

    &:nth-child(odd) {
      background-color: $gray-400;
    }

    .results-table-col {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 0.9rem;
      vertical-align: middle;
      padding-right: 0;

      &.results-table-col-label {
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.results-table-col-clade {
        width: 20px;
        text-align: center;
      }

      &.results-table-col-mutations,
      &.results-table-col-gene-map {
        padding: 0;
        width: 65%;
        border: 1px solid $gray-500;
      }

      &.results-table-col-axis {
        padding: 0;
        width: 65%;
        border: none;

        .recharts-wrapper {
          border: none;
        }
      }
    }
  }

  .results-table-danger {
    .results-table-col {
      color: #e51c23;
      background-color: #e9d2d3;
    }
  }
}
